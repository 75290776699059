import React, { useState } from 'react';
import { Marker, Polygon, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

import { TrLine } from '../helpers/TrLine';
import styles from '../styles/Home.module.css';
import trackingStore from '../Store/trackingStore';
import { observer } from 'mobx-react-lite';
import settingsStore from '../Store/settingsStore';

function AirSpacesView({ zones, type }) {
  if (!zones || zones == '[]') return;
  const [zoomLevel, setZoomLevel] = useState(9); // initial zoom level provided for MapContainer
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
      //console.log('zoom:' + zoomLevel);
    }
  });

  const airSpaceZones = [];
  //console.log(zones);
  zones.forEach((zone) => {
    if (!settingsStore.showAirSpaces.listOfDisabled.includes(zone.AC)) {
      let label = '';
      if (zone.AN !== undefined) label = zone.AN;
      let zoneClass = 'airDefaultZone';
      let zoneLabel = new L.divIcon({
        html: `
          <div style="display: flex; justify-content: center;">
            <b style="
              position: absolute;
              border: 0px solid;
              border-radius: 3px;
              font-weight: bolder;
              font-size: 12px;
              padding-left: 0px;
              padding-right: 0px;
              top: 0px;
              text-align: center;
              color: #565454;
              padding-left: 2px;
              padding-right: 2px;
            ">${label}</b>
          </div>
        `,
        iconSize: [10, 10],
        className: 'glider-icon-cn'
      });
      if (type == 'penaltyZones') {
        zoneClass = 'airPenaltyZone';
        zoneLabel = new L.divIcon({
          html: `
            <div style="display: flex; justify-content: center;">
              <b style="
                position: absolute;
                border: 0px solid;
                border-radius: 3px;
                font-weight: bolder;
                font-size: 12px;
                padding-left: 0px;
                padding-right: 0px;
                top: 0px;
                text-align: center;
                background-color: yellow;
                padding-left: 2px;
                padding-right: 2px;
              ">${label}</b>
            </div>
          `,
          iconSize: [10, 10],
          className: 'glider-icon-cn'
        });
      } else if (zone.AL == 'SFC' || zone.AL == 'GND') {
        zoneClass = 'redFillAirZone';
      } else if (zone.AC == 'P' || zone.AC == 'R' || zone.AC == 'Q') {
        zoneClass = 'redAirZone';
      } else if (zone.AC == 'C') {
        zoneClass = 'blueAirZone';
      } else if (zone.AC == 'W') {
        zoneClass = 'greenAirZone';
      }

      const positions = zone.points.map((point) => {
        return [point.lat, point.lon];
      });
      airSpaceZones.push(
        <Polygon
          key={'fzp' + zone.id}
          positions={positions}
          interactive={true}
          className={styles[zoneClass]}
          eventHandlers={{
            // contextmenu: (e) => {
            //   // this.setStyle({
            //   //   fillOpacity: 0.2,
            //   //   opacity: 0.75
            //   // });
            // },
            mouseover: function () {
              if (type == 'penaltyZones') trackingStore.setActiveAirSpace('Penalty', zone.AL, zone.AH, zone.AN);
              else trackingStore.setActiveAirSpace(zone.AC, zone.AL, zone.AH, zone.AN);
            },
            mouseout: function () {
              trackingStore.unsetActiveAirSpace();
            }
            // mouseup: function (e) {
            //   console.log('mouseup');
            //   console.log(e);
            // }
          }}
        />
      );
      airSpaceZones.push(
        (zoomLevel > 9 || type == 'penaltyZones') && (
          <Marker key={'fzm' + zone.id} position={[zone.lat, zone.lon]} icon={zoneLabel}>
            <Popup>
              <table>
                <tbody>
                  {zone.AC !== undefined && <TrLine a="AC" b={zone.AC} />}
                  {zone.AY !== undefined && <TrLine a="AY" b={zone.AY} />}
                  {zone.AN !== undefined && <TrLine a="AN" b={zone.AN} />}
                  {zone.AL !== undefined && <TrLine a="AL" b={zone.AL} />}
                  {zone.AH !== undefined && <TrLine a="AH" b={zone.AH} />}
                </tbody>
              </table>
            </Popup>
          </Marker>
        )
      );
    }
  });
  return airSpaceZones;
}

export default observer(AirSpacesView);
